import { useState, useRef, useCallback } from "react";
import { staff_data } from "../utils/config/staff.js";
import { department_data } from "../utils/config/staff.js";
import { insertTextbeforeUrlExtention } from "../utils/general/text-formatting.js";
import { template_image_light } from "../utils/config/image-links.js";
import { handleScaleInlineTailwind300 } from "../utils/animations/animations.js";
import { ReactComponent as Hamburger } from "../utils/svg_components/hamburger.svg";
import { res_about_us_staff_image } from "../utils/config/image-resolutions.js";
import { handleMouseMove, handleMouseLeave } from "../utils/animations/tilt-animation-functions.js";
import { DivWithLoadingBackground } from "./ImageLoaders.jsx";

export default function DepartmentsPeople() {
  const buttonRefs = useRef([]);

  const throttledMouseMove = useRef(handleMouseMove(buttonRefs));
  // eslint-disable-next-line
  const handleMouseMoveCallback = useCallback(throttledMouseMove.current, []);
  // eslint-disable-next-line
  const handleMouseLeaveCallback = useCallback(handleMouseLeave(buttonRefs, throttledMouseMove.current), []);

  const [selectedDepartment, setSelectedDepartment] = useState("executive");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="flex flex-col px-[10%] py-12">
      <div className="flex flex-col border border-gray-200 shadow-xl rounded-lg p-4 sm:p-8 gap-6">
        <div className="flex w-full text-2xl font-semibold text-castell justify-center lg:justify-start border-b border-gray-200 pb-4">
          Meet the Team
        </div>
        <div className="flex flex-col lg:flex-row gap-4 sm:gap-8">
          <div className="hidden lg:flex flex-col gap-2">
            {Object.values(department_data).map((department) => (
              <button
                aria-label={`Filter staff by ${department.name}`}
                key={department.id}
                className={`px-4 py-2 ${
                  department.id === selectedDepartment ? "bg-castell text-white" : "hover:bg-castellpastel-100"
                } font-semibold whitespace-nowrap text-left w-full border border-gray-200 rounded-sm ${handleScaleInlineTailwind300}`}
                onClick={() => setSelectedDepartment(department.id)}
                onMouseEnter={(e) => e.currentTarget.classList.add("scale-110")}
                onMouseLeave={(e) => e.currentTarget.classList.remove("scale-110")}
              >
                {department.name}
              </button>
            ))}
          </div>
          <div className="flex w-full relative lg:hidden">
            <button
              aria-label="Open department filter"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="flex justify-center text-castell w-full items-center py-2"
            >
              <Hamburger strokeWidth="1.2" className="w-6 stroke-castell fill-white" />
              <div className="flex ml-4 font-semibold">Filter by Department</div>
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full left-0 w-full bg-white rounded-md z-40 shadow-lg border border-gray-200">
                <div className="flex flex-col text-castell font-semibold">
                  {Object.values(department_data).map((department) => (
                    <button
                      aria-label={`Filter staff by ${department.name}`}
                      key={department.id}
                      className={`px-2 py-1 ${
                        department.id === selectedDepartment ? "bg-castell text-white" : "hover:bg-castellpastel-100"
                      } font-semibold whitespace-nowrap text-left w-full rounded-sm`}
                      onClick={() => {
                        setSelectedDepartment(department.id);
                        setIsDropdownOpen(false);
                      }}
                    >
                      {department.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 4.5xl:grid-cols-6 5xl:grid-cols-8 6xl:grid-cols-10  w-full h-full gap-4 sm:gap-8 text-black font-semibold text-xs md:text-sm">
            {Object.values(staff_data)
              .filter((staff) => (selectedDepartment === "all" ? staff : staff.department.id === selectedDepartment))
              .map((staff, index) => (
                <div
                  key={staff.employee_number}
                  ref={(el) => (buttonRefs.current[index] = el)}
                  className={`flex flex-col border border-gray-200 rounded-lg overflow-hidden transition-transform duration-150 shadow-lg`}
                  onMouseMove={(e) => handleMouseMoveCallback(e, index)}
                  onMouseLeave={() => handleMouseLeaveCallback(index)}
                >
                  <DivWithLoadingBackground
                    className="flex w-full h-52 2xl:h-64 bg-cover border-b border-gray-200 bg-center bg-blue-200"
                    imageUrl={insertTextbeforeUrlExtention(
                      staff.main_image_url || template_image_light,
                      res_about_us_staff_image
                    )}
                  />
                  <div className="flex flex-col w-full items-center px-2 py-2">
                    <div className="whitespace-nowrap">{`${staff.first_name} ${staff.last_name}`}</div>
                    <div className="text-center">{`${staff.job_title}`}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
